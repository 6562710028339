<template>
    <div class="DeviceDetail">
        <el-card class="box-card">
            <i class="el-icon-close cha" @click="$router.go(-1)"></i>

            <div class="clearfix">
                基本信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">编号：</div>
                    <div class="info">{{ deviceInfo.id }}</div>
                </div>
                <div class="item">
                    <div class="label">仪器分类：</div>
                    <div class="info">{{ deviceInfo.deviceOneClassDesc }}</div>
                </div>
                <div class="item">
                    <div class="label">仪器名称：</div>
                    <div class="info">{{ deviceInfo.deviceName }}</div>
                </div>

            </div>
            <div class="df">
                <div class="item">
                    <div class="label">实际名称：</div>
                    <div class="info">{{ deviceInfo.deviceRealName }}</div>
                </div>
                <div class="item">
                    <div class="label">仪器型号：</div>
                    <div class="info">{{ deviceInfo.deviceType }}</div>
                </div>
                <div class="item">
                    <div class="label">仪器编号：</div>
                    <div class="info">{{ deviceInfo.deviceNum }}</div>
                </div>

            </div>
            <div class="df">
                <div class="item">
                    <div class="label">仪器条码:</div>
                    <div class="info">{{ deviceInfo.deviceCode }}</div>
                </div>
                <div class="item">
                    <div class="label">购置日期：</div>
                    <div class="info">{{ deviceInfo.purchaseDate }}</div>
                </div>
                <div class="item">
                    <div class="label">生产厂家：</div>
                    <div class="info">{{ deviceInfo.manufacturer }}</div>
                </div>

            </div>
            <div class="df">
                <div class="item">
                    <div class="label">所在库房</div>
                    <div class="info">{{ deviceInfo.whCodeName }}</div>
                </div>
                <div class="item">
                    <div class="label">保存地点：</div>
                    <div class="info">{{ deviceInfo.location }}</div>
                </div>
                <div class="item" style="font-weight: 700;">
                    <div class="label">仪器状态：</div>
                    <div class="info">{{ deviceInfo.deviceStatus_test }}</div>
                </div>
            </div>

            <div class="df">
                <div class="item" style="font-weight: 700;">
                    <div class="label">出入库状态：</div>
                    <div class="info">{{ deviceInfo.crkStatus_test }}</div>
                </div>
                <div class="item">
                    <div class="label">仪器功能：</div>
                    <div class="info">{{ deviceInfo.deviceFun }}</div>
                </div>
                <div class="item">
                    <div class="label">评级：</div>
                    <div class="info">{{ deviceInfo.deviceGrade_test }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">配置分析：</div>
                    <div class="info">{{ deviceInfo.deployAnalyst }}</div>
                </div>
                <div class="item">
                    <div class="label">购置需求：</div>
                    <div class="info">{{ deviceInfo.purchaseDemand }}</div>
                </div>
                <div class="item">
                    <div class="label">上次维修日期：</div>
                    <div class="info">{{ deviceInfo.lastWxDate }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">仪器是否合格：</div>
                    <div class="info">{{ deviceInfo.isCheckoutOk_text }}</div>
                </div>
                <div class="item">
                    <div class="label">标签：</div>
                    <div v-if="deviceInfo.xyTag">
                        <div class="info" v-for=" (item, index) in xyTagList" :key="index">
                            <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="xyTagList"
                                fit="cover"></el-image>
                        </div>
                    </div>

                </div>
                <div class="item" style="">
                    <div class="label">证书：</div>
                    <a style="text-decoration: none;" target="_blank" :href="path + deviceInfo.xyCerPath">{{
                        deviceInfo.xyCer
                    }}</a>
                </div>
            </div>
            <div class="df">
                <div class="item" style="font-weight: 700;">
                    <div class="label">校验保养预警：</div>
                    <div class="info">{{ deviceInfo.caution_text }}</div>
                </div>
                <div class="item">
                    <div class="label">录入时间：</div>
                    <div class="info">{{ deviceInfo.enterTime }}</div>
                </div>
                <div class="item">
                    <div class="label">备注：</div>
                    <div class="info">{{ deviceInfo.remark }}</div>
                </div>
            </div>

        </el-card>

        <el-card>
            <div class="clearfix">
                仪器仪表来源信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">来源：</div>
                    <div class="info">{{ deviceInfo.source_text }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 0">
                    <div class="label">购买人：</div>
                    <div class="info">{{ deviceInfo.buyer }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 0">
                    <div class="label">购买金额（元）：</div>
                    <div class="info">{{ deviceInfo.purchasePrice }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 1">
                    <div class="label">借出方：</div>
                    <div class="info">{{ deviceInfo.lender }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 1">
                    <div class="label">借入人：</div>
                    <div class="info">{{ deviceInfo.borrower }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 1">
                    <div class="label">借入时间：</div>
                    <div class="info">{{ deviceInfo.borrowDate }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 2">
                    <div class="label">租赁方：</div>
                    <div class="info">{{ deviceInfo.leasee }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 2">
                    <div class="label">租赁人：</div>
                    <div class="info">{{ deviceInfo.leaseholder }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 2">
                    <div class="label">租赁费用(元)：</div>
                    <div class="info">{{ deviceInfo.leaseePrice }}</div>
                </div>
                <div class="item" v-if="deviceInfo.source === 2">
                    <div class="label">租赁日期：</div>
                    <div class="info">{{ deviceInfo.leaseeDate }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                仪器仪表校验信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">是否需要校验：</div>
                    <div class="info">{{ deviceInfo.isCheck_text }}</div>
                </div>
                <div class="item">
                    <div class="label">上次校验日期：</div>
                    <div class="info">{{ deviceInfo.lastCheckDate }}</div>
                </div>
                <div class="item">
                    <div class="label">校验周期（天）：</div>
                    <div class="info">{{ deviceInfo.checkCycle }}</div>
                </div>
                <div class="item">
                    <div class="label">校验日期：</div>
                    <div class="info">{{ deviceInfo.checkDate }}</div>
                </div>
            </div>
        </el-card>
        <el-card>
            <div class="clearfix">
                仪器仪表保养信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">是否需要保养：</div>
                    <div class="info">{{ deviceInfo.isMaintain_text }}</div>
                </div>
                <div class="item">
                    <div class="label">上次保养日期：</div>
                    <div class="info">{{ deviceInfo.lastMaintainDate }}</div>
                </div>
                <div class="item">
                    <div class="label">保养周期（天）：</div>
                    <div class="info">{{ deviceInfo.maintainCycle }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                设备详情图片
            </div>
            <div class="df-img">

                <div class="info" v-if="imgsList">
                    <div class="block" v-for="(item, index) in imgsList" :key="index">
                        <!-- <span class="demonstration">自定义</span> -->
                        <el-image :src="item" :preview-src-list="imgsList">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </el-card>


    </div>
</template>

<script>

import { queryDevice, deviceStatusList, crkStatusList } from '@/api/device'
import { assessList } from '@/api/deviceAssess.js'
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            deviceStatusList: deviceStatusList,
            crkStatusList: crkStatusList,
            assessList: assessList,
            deviceInfo: {
                deviceName: '',
                deviceRealName: '',
                deviceCode: '',
                deviceType: '',
                deviceNum: '',
                purchaseDate: '',
                manufacturer: '',
                deviceinfoImg: '',
                isAttachment: 0,
                attachmentImg: '',
                isCheck: 0,
                lastCheckDate: '',
                checkCycle: '',
                checkDate: '',
                isMaintain: 1,
                lastMaintainDate: '',
                maintainCycle: '',
                deviceFun: '',
                location: '',
                source: 0,
                buyer: '',
                purchasePrice: '',
                lender: '',
                borrower: '',
                borrowDate: '',
                leasee: '',
                leaseholder: '',
                leaseePrice: '',
                leaseeDate: '',
                remark: ''
            },

            imgsList: [],
            xyTagList: [],
            path: process.env.VUE_APP_URL + this.UploadPath

        }
    },
    mounted() {
        this.$route.params.id && this.loadDeviceInfo()
    },
    methods: {
        //根据id查询设备信息
        async loadDeviceInfo() {
            await queryDevice(this.$route.params.id).then(res => {
                if (res.code === '000000') {
                    this.deviceInfo = res.t
                    if (this.deviceInfo.xyTag !== '') {
                        this.xyTagList.push(this.path + this.deviceInfo.xyTag)
                    }

                    this.deviceInfo.isAttachment_text = this.deviceInfo.isAttachment === 0 ? '是' : '否'
                    this.deviceInfo.isCheck_text = this.deviceInfo.isCheck === 0 ? '需要' : '不需要'
                    this.deviceInfo.isMaintain_text = this.deviceInfo.isMaintain === 0 ? '需要' : '不需要'
                    this.deviceInfo.isCheckoutOk_text = this.deviceInfo.isCheckoutOk === 0 ? '合格' : '不合格'
                    this.deviceInfo.source_text = this.deviceInfo.source === 0 ? '新增' : (this.deviceInfo.source === 1 ? '借入' : '租聘')
                    const index = this.deviceStatusList.findIndex(item => item.code === this.deviceInfo.deviceStatus)
                    const cautain = this.deviceInfo.caution
                    console.log('cautain')
                    console.log(cautain)
                    switch (cautain) {
                        case '00':
                            this.deviceInfo.caution_text = '正常'
                            break;
                        case '01':
                        case '11':
                        case '21':
                            this.deviceInfo.caution_text = '校验预警'
                            break;
                        case '02':
                        case '12':
                        case '22':
                            this.deviceInfo.caution_text = '超期校验'
                            break;
                        case '10':
                            this.deviceInfo.caution_text = '保养预警'
                            break;
                        case '20':
                            this.deviceInfo.caution_text = '保养告警'
                            break;
                        default:
                            this.deviceInfo.caution_text = ''
                            break;
                    }
                    if (index !== -1) {
                        this.deviceInfo.deviceStatus_test = this.deviceStatusList[index].name
                    }

                    const indexValue = this.crkStatusList.findIndex(item => item.code === this.deviceInfo.crkStatus)
                    if (indexValue !== -1) {
                        this.deviceInfo.crkStatus_test = this.crkStatusList[indexValue].name
                    }

                    const indexAsses = this.assessList.findIndex(item => item.code === this.deviceInfo.deviceGrade)
                    if (indexAsses !== -1) {
                        this.deviceInfo.deviceGrade_test = this.assessList[indexAsses].name
                    }

                    if (this.deviceInfo.deviceinfoImg != null && this.deviceInfo.deviceinfoImg != '') {
                        const list2 = this.deviceInfo.deviceinfoImg.split(";")
                        list2.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = this.path + item
                                this.imgsList.push(obj)
                            }
                        })
                    }
                }
            })
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceDetail {
    .el-card {
        position: relative;
        text-align: left;
        margin-bottom: 15px;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .clearfix {
        margin-bottom: 20px;
        font-size: 16px;
        color: #1d2129;
    }


    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }

    .df:last-child {
        margin-bottom: 0;
    }

    .df {
        display: flex;
        margin-bottom: 16px;

        .item {
            width: 33.333333%;
            display: flex;
            font-size: 14px;
            color: #4e5969;

            .label {
                margin-right: 8px;
            }

            .info {
                width: 250px;
                float: left;
            }
        }
    }

    .df-img {
        width: 100%;
        margin-bottom: 16px;

        .info {
            width: 100%;
            // float: left;
            display: flex;

            .block {
                width: 25%;
                margin-right: 10px;

                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }


}
</style>